<template>
  <b-container fluid>
    <b-card>
      <b-row>
        <b-col sm="12" lg="3">
          <v-select
            v-model="currentBranch"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="branchOptions"
            :clearable="true"
            label="area"
            class="font-small-3 w-100 mb-1 mr-1"
            placeholder="Select Area"
          >
            <template #option="option">
              <span>{{ option.area }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col sm="12" lg="3">
          <v-select
            v-model="currentOrderType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="orderTypeOptions"
            :clearable="true"
            label="area"
            class="font-small-3 w-100 mb-1 mr-1"
            placeholder="Select Franchise"
          >
            <template #option="option">
              <span>{{ option.area }}</span>
            </template>
          </v-select>
        </b-col>
        <b-col sm="12" lg="4" class="d-flex">
          <b-button
            variant="gradient-primary"
            class="mb-1 mr-1"
            @click.prevent="setFilter()"
          >
            <span class=""> Filter </span>
            <feather-icon icon="FilterIcon" size="14" class="text-white ml-1" />
          </b-button>
        </b-col>
        <b-col sm="12" lg="2" class="d-flex justify-content-end">
          <b-button
            class="mb-1"
            variant="gradient-primary"
            @click.prevent="saveRoute()"
          >
            <span class=""> Save Route </span>
            <feather-icon icon="MapPinIcon" size="14" class="text-white ml-1" />
          </b-button>
        </b-col>
      </b-row>
      <div class="full-screen" v-if="this.locations.length != 0">
        <GoogleMap
          :locations="locations"
          :mapOptions="mapOptions"
          class="h-100"
        />
      </div>
    </b-card>
  </b-container>
</template>

<script>
import GoogleMap from "@/views/components/GoogleMap.vue";
import vSelect from "vue-select";

export default {
  components: {
    GoogleMap,
    vSelect,
  },

  created() {
    this.getBranches();
  },

  methods: {
    getBranches() {
      this.isLoading = true;
      this.$http.get(`branch`).then((response) => {
        if (response.data.status) {
          this.branchOptions = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        this.isLoading = false;
      });
    },

    changeBranch() {
      console.log("changeBranch");
    },

    setFilter() {
      this.locations = [];
      this.isLoading = true;
      this.$http
        .get(
          `admin/get_orders?branch_id=${this.currentBranch.id}&order_type=${this.currentOrderType}`
        )
        .then((response) => {
          for (let x = 0; x < response.data.data.length; x++) {
            this.locations.push([
              {
                lat: response.data.data[x].recipient.lat,
                lng: response.data.data[x].recipient.lng,
              },
            ]);
            this.locations[x][1] =
              `Order ID: ` +
              response.data.data[x].id +
              "\nOrder Type: " +
              response.data.data[x].order_type +
              "\nRecipient Address: " +
              response.data.data[x].recipient.complete_address +
              "\nRemarks: " +
              response.data.data[x].remarks;
          }
          console.log(this.locations[0][0]);
          console.log(this.locations);
          this.isLoading = false;
          if (this.locations.length == 0) {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: "No Orders To Display",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },

    saveRoute() {
      console.log("saveRoute");
    },
  },

  data() {
    return {
      currentBranch: null,
      branchOptions: [],
      currentOrderType: "",
      orderTypeOptions: ["Distributor", "Franchise", "Singapore"],
      locations: [],
      mapOptions: {
        zoom: 9,
        center: { lat: 2.731813, lng: 102.252502 },
      },
    };
  },
};
</script>

<style>
.full-screen {
  height: 80vh;
}
</style>
