<template>
  <div id="map" class="mapbox-container"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "Map",
  components: {},

  props: {
    locations: {
      type: Array,
      required: true,
    },
    mapOptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      accessToken: process.env.VUE_APP_GOOGLEMAP_ACCESS_TOKEN,
      orderId: "69",
      order_type: "Distributor",
      order_recipient_address:
        "Blk 1, Jade Street, Emerald Plains, 01000, Puchong, Penang, Malaysia",
      order_remarks: "Item is cold and good",
    };
  },

  mounted() {
    this.createMap();
  },

  methods: {
    async createMap() {
      const loader = new Loader({
        apiKey: this.accessToken,
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then((google) => {
          const map = new google.maps.Map(
            document.getElementById("map"),
            this.mapOptions
          );
          const infoWindow = new google.maps.InfoWindow();

          // Create the markers.
          this.locations.forEach(([position, title]) => {
            const marker = new google.maps.Marker({
              position,
              map,
              title: `${title}`,
              content: `${title}`,
              optimized: true,
            });

            marker.addListener("onhover", () => {
              infoWindow.close();
              infoWindow.setContent(marker.getTitle());
              infoWindow.open(marker.getMap(), marker);
            });

            marker.addListener("click", () => {
              // infoWindow.close();
              // infoWindow.setContent(marker.getTitle());
              // infoWindow.open(marker.getMap(), marker);
              console.log(title);
            });

            //Add marker line creator on click
            // marker.addListener("onhover", () => {
            //   infoWindow.close();
            //   infoWindow.setContent(marker.getTitle());
            //   infoWindow.open(marker.getMap(), marker);
            // });
          });
        })
        .catch((e) => {
          // do something
        });
    },
  },
};
</script>

<style scoped>
/* NOTE: MapBox follows the size of its parent container -Kit */
.mapbox-container {
  height: 100%;
  width: 100%;
}
</style>
